import React, { useState } from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import { useSharedStores } from 'shared/hooks/use-shared-stores'
import { EloModal, EloModalBody, EloModalFooter, EloModalHeader } from '@elo-ui/components/elo-modal'
import { EloButton } from '@elo-ui/components/elo-button'
import { notify } from 'libs/common/notify'
import { formatToMoney } from 'utils/money.utils'
import { addToDataLayer } from 'utils/GTMDataLayer.utils'
import { getUpgradeFlowDataLayerProps, UpgradeFlowElement } from 'utils/OnboardingBannerDataLayer.utils'
import { SellerStore } from 'shared/stores/seller.store'
import { useDidMount } from 'shared/hooks/use-did-mount-hook'

import './_pricing-plan-item.scss'

interface Plan {
  id: number
  cover: any
  description: string
  name: string
  title?: string
  price: number
  released: boolean
  checkoutUrlMonthly?: string
}

interface Props {
  plan?: Plan
  classes?: string
  isOpen: boolean
  onModalClose: () => void
  optionKey?: string
  planId?: string
  sellerStore?: SellerStore
  trackingElement?: UpgradeFlowElement
  trackingEventName?: string
  fromMarketing?: boolean
}

const PricingPlanItem = ({ description, cover, name, price }) => (
  <div className='pricing-plan-item'>
    <p className='pricing-plan-item__description'>{description}</p>
    <div className='pricing-plan-item__content'>
      <img className='pricing-plan-item__img' src={cover} alt='Icon' />
      <div className='pricing-plan-item__card'>
        <div className='pricing-plan-item__name'>{name}</div>
        <div className='pricing-plan-item__price'>
          {`${formatToMoney(price)}`} <span>({I18n.t('react.shared.subs.app.montly_plan')})</span>
        </div>
      </div>
    </div>
  </div>
)

export const ConfirmUpgradeModal: React.FC<Props> = observer(function PlansListModal(props) {
  const {
    classes,
    onModalClose,
    isOpen,
    plan,
    optionKey,
    planId,
    sellerStore,
    trackingElement,
    trackingEventName,
    fromMarketing,
  } = props
  const [isLoading, setIsLoading] = useState(false)
  const { bookingStore } = useSharedStores()
  const modalClasses = classNames('plan-item__confirm-plan-modal', classes)
  const option = optionKey || 'marketing_site'

  useDidMount(() => {
    fromMarketing &&
      addToDataLayer(
        getUpgradeFlowDataLayerProps({
          element: 'marketing_site_plan_confirmation',
          optionKey: option,
          planId: sellerStore?.item?.planId,
          isClick: false,
          nextPlanId: Number(plan ? plan.id : planId),
        })
      )
  })

  return (
    <EloModal isOpen={isOpen} onClose={onModalClose} className={modalClasses} size='small' isOverlayFixed>
      <EloModalHeader className='plans-list-modal__header'>
        <h2>{I18n.t('react.shared.subs.plan.upgrade_your_plan')}</h2>
      </EloModalHeader>
      <EloModalBody>
        <div>
          <PricingPlanItem
            description={
              fromMarketing || optionKey === 'upgrade_plan_flow'
                ? plan.description
                : I18n.t('react.shared.subs.plan.need_upgrade')
            }
            cover={plan?.cover?.s100 || plan?.cover?.s140}
            name={plan.name}
            price={parseInt(String(plan.price || 0), 10)}
          />
          <p className='pricing-plan-item__sub_text'>
            {I18n.t('shared.common.plans.confirm_upgrade_modal_description')}
          </p>
          <p
            className='pricing-plan-item__sub_text'
            dangerouslySetInnerHTML={{ __html: I18n.t('shared.common.plans.confirm_upgrade_modal_text') }}
          />
        </div>
      </EloModalBody>
      <EloModalFooter className='plans-list-modal__footer'>
        <EloButton variant='secondary' onClick={onModalClose}>
          {I18n.t('react.shared.cancel')}
        </EloButton>
        <EloButton
          disabled={isLoading}
          variant='highlight'
          onClick={async () => {
            setIsLoading(true)
            const resp = await bookingStore.bookPlan(plan ? plan.id : planId)

            if (resp.success) {
              addToDataLayer(
                getUpgradeFlowDataLayerProps({
                  element:
                    trackingElement || (!fromMarketing ? 'plan_confirmation' : 'marketing_site_plan_confirmation'),
                  optionKey: option,
                  planId: sellerStore?.item?.planId,
                  isClick: true,
                  nextPlanId: Number(plan ? plan.id : planId),
                  eventName: trackingEventName,
                })
              )
              notify('success', I18n.t('react.cabinet.notific.success'))

              if (fromMarketing) {
                window.location.href = '/cabinet/billing'
              } else {
                setTimeout(() => {
                  window.location.reload()
                }, 1000)
              }
            } else {
              setIsLoading(false)
              addToDataLayer(
                getUpgradeFlowDataLayerProps({
                  element:
                    trackingElement || (!fromMarketing ? 'plan_confirmation' : 'marketing_site_plan_confirmation'),
                  optionKey: option,
                  planId: sellerStore.item.planId,
                  isClick: true,
                  reason: `${resp.error}`,
                  nextPlanId: Number(plan ? plan.id : planId),
                  eventName: trackingEventName,
                })
              )
            }
          }}
        >
          {I18n.t('shared.common.plans.confirm_upgrade')}
        </EloButton>
      </EloModalFooter>
    </EloModal>
  )
})
