import { useEffect, useRef } from 'react'

export const useDidMount = (callback) => {
  const didMount = useRef(null)

  useEffect(() => {
    if (callback && !didMount.current) {
      didMount.current = true
      callback()
    }
  }, [])
}
