import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'

import { EloModal, EloModalBody, EloModalFooter, EloModalHeader } from '@elo-ui/components/elo-modal'
import { EloLink } from '@elo-ui/components/elo-link'
import { LoadingSpinner } from '@elo-kit/components/loading-spinner/LoadingSpinner'
import { EloButton } from '@elo-ui/components/elo-button'
import { ConfirmUpgradeModal } from 'shared/components/onboarding-banner/ConfirmUpgradeModal'
import { addToDataLayer } from 'utils/GTMDataLayer.utils'
import { getUpgradeFlowDataLayerProps, UpgradeFlowElement } from 'utils/OnboardingBannerDataLayer.utils'
import { UPGRADE_PLAN_LINK } from 'constants/helpCenter.constants'
import { isTrialPeriodEnd, isOnTrialPeriod } from 'utils/seller.utils'
import { formatToMoney } from 'utils/money.utils'
import { EloStarSelectedIcon } from '@elo-ui/components/icons/regular'
import { useCabinetStores } from '../../hooks/use-cabinet-stores'

import './upgrade-plan-list-modal.scss'

interface PlanItemProps {
  name: string
  description: string
  price: number
  recommended: boolean
  selected: boolean
  best: boolean
  isTrialHold: boolean
  handleClick: () => void
}

const PlanItem: React.FC<PlanItemProps> = ({
  name,
  description,
  price,
  recommended,
  selected,
  handleClick,
  isTrialHold,
  best,
}) => {
  const cardClassNames = classNames('upgrade-plan-card', {
    'upgrade-plan-card__selected': selected,
  })

  return (
    <div className={cardClassNames} onClick={handleClick}>
      {isTrialHold && recommended && (
        <div className='upgrade-plan-card__recommended-badge'>{I18n.t('react.shared.the_same_plan')}</div>
      )}
      {!isTrialHold && best && (
        <div className='upgrade-plan-card__recommended-badge'>{I18n.t('react.shared.recommended')}</div>
      )}
      <div>
        <div className='upgrade-plan-card__name'>{name}</div>
        <div className='upgrade-plan-card__description'>{description}</div>
      </div>
      <div className='upgrade-plan-card__price'>
        {formatToMoney(price)} <span>{I18n.t('react.shared.subs.app.montly_plan')}</span>
      </div>
    </div>
  )
}

interface Props {
  isOpen?: boolean
  trackingElement: UpgradeFlowElement
  onModalClose: () => void
}

export const UpgradePlanListModal: React.FC<Props> = observer((props) => {
  const { upgradePlansStore, sellerStore } = useCabinetStores()
  const [isOpen, setIsOpen] = useState(props.isOpen)
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)
  const [selectedPlanIndex, setSelectedPlanIndex] = useState(0)

  const init = async () => {
    await upgradePlansStore.fetchList()
    const initialPlanIndex = upgradePlansStore.list?.findIndex((i) => i.recommended) || 0
    setSelectedPlanIndex(initialPlanIndex)
  }

  const onModalClose = () => {
    setIsOpen(false)
    props.onModalClose()
  }

  useEffect(() => {
    init()
  }, [])

  return (
    <EloModal size='large' isOpen={isOpen} onClose={onModalClose} className='upgrade-plan-list-modal' isOverlayFixed>
      <EloModalHeader className='plans-list-modal__header'>
        <h2>{I18n.t('react.shared.upgrade_modal_title')}</h2>
      </EloModalHeader>
      <EloModalBody>
        {upgradePlansStore.loading && <LoadingSpinner />}
        {upgradePlansStore.list?.[selectedPlanIndex]?.shortOption && (
          <div className='upgrade-plan-list__features'>
            {upgradePlansStore.list?.[selectedPlanIndex]?.shortOption
              .split('|')
              .slice(0, 6)
              .map((item, idx) => (
                <span key={idx}>
                  {' '}
                  <EloStarSelectedIcon /> {item}
                </span>
              ))}
          </div>
        )}
        <div className='upgrade-plan-list'>
          {!upgradePlansStore.loading &&
            upgradePlansStore.list?.map((plan, idx) => (
              <PlanItem
                key={idx}
                name={plan.name}
                description={plan.description}
                price={plan.price}
                recommended={plan.recommended}
                best={plan.best}
                isTrialHold={isTrialPeriodEnd(sellerStore.item.planId) || isOnTrialPeriod(sellerStore.item.planId)}
                handleClick={() => {
                  setSelectedPlanIndex(idx)
                }}
                selected={selectedPlanIndex === idx}
              />
            ))}
        </div>
        <EloLink
          onClick={() => {
            addToDataLayer(
              getUpgradeFlowDataLayerProps({
                element: 'marketing_site_plans_list',
                planId: sellerStore?.item?.planId,
                optionKey: 'upgrade_plan_flow',
                sellerId: sellerStore?.item.id,
                isClick: true,
              })
            )
            window.open(UPGRADE_PLAN_LINK)
          }}
        >
          {I18n.t('react.shared.pricing_link')}
        </EloLink>
      </EloModalBody>
      <EloModalFooter className='elo-ui-modal__footer--end'>
        <EloButton variant='secondary' onClick={props.onModalClose}>
          {I18n.t('react.shared.button.cancel')}
        </EloButton>
        <EloButton
          variant='highlight'
          disabled={!upgradePlansStore.list?.length}
          onClick={() => {
            setIsConfirmationOpen(true)
            addToDataLayer(
              getUpgradeFlowDataLayerProps({
                element: props.trackingElement,
                eventName: 'plan_selection_click',
                planId: sellerStore?.item?.planId,
                nextPlanId: Number(upgradePlansStore.list?.[selectedPlanIndex]?.id),
                isClick: true,
                sellerId: sellerStore?.item.id,
                optionKey: 'upgrade_plan_flow',
              })
            )
          }}
        >
          {I18n.t('react.shared.button.continue_to_upgrade')}
        </EloButton>
      </EloModalFooter>
      {isConfirmationOpen && (
        <ConfirmUpgradeModal
          isOpen={isConfirmationOpen}
          plan={upgradePlansStore.list?.[selectedPlanIndex]}
          onModalClose={() => {
            setIsConfirmationOpen(false)
          }}
          trackingElement={props.trackingElement}
          trackingEventName='plan_confirmation_click'
          optionKey='upgrade_plan_flow'
          sellerStore={sellerStore}
        />
      )}
    </EloModal>
  )
})
