import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { NavLink, useHistory } from 'react-router-dom'
import classNames from 'classnames'

import { EloAvatar } from '@elo-ui/components/elo-avatar'
import { EloPopover } from '@elo-ui/components/elo-popover'
import { EloUserDisplay } from '@elo-ui/components/elo-user-display'
import { EloRadioButton } from '@elo-ui/components/elo-radio-button'
import { EloButton } from '@elo-ui/components/elo-button'
import { useI18n } from '@elo-kit/components/i18n/i18n'
import { EloWarningIcon } from '@elo-ui/components/icons/regular'
import { EloStatusBadge } from '@elo-ui/components/elo-status-badge'

import {
  ACTIVE_PROFILES,
  STARTED_WITH_ELOPAGE_KEY,
  PROFILES_ROOT_PATH,
  getNewProfileRequiredFields,
} from 'constants/profile.constants'
import { PAYER_BANK_ACCOUNTS_PATH, PAYER_PROFILE_PATH } from 'constants/routes/payer.constants'
import { PUBLISHER_BANK_ACCOUNTS_PATH, PUBLISHER_PROFILE_PATH } from 'constants/routes/publisher.constants'
import { ELO_PUBLISHER_BANK_ACCOUNTS_PATH, ELO_PUBLISHER_PROFILE_PATH } from 'constants/routes/eloPublisher.constants'
import { TEAM_MEMBER_BANK_ACCOUNTS_PATH, TEAM_MEMBER_PROFILE_PATH } from 'constants/routes/teamMember.constants'
import { SALES_TEAM_MEMBER_PROFILE_PATH } from 'constants/routes/salesTeamMember.constants'
import { getAllowedLanguages } from 'constants/general.constants'
import { SUPPORT_LINKS, SELLER_SUPPORT_LINKS, UPGRADE_PLAN_LINK } from 'constants/helpCenter.constants'
import { SELLER_CLICK_EVENT } from 'constants/GTMDataLayer.constants'
import { DEFAULT_FALLBACK_LOCALE } from 'constants/locale.constants'
import { NO, YES } from '@elo-kit/constants/general.constants'

import { getProfileFullName, isProfileCompleted } from 'utils/user.utils'
import { removeTokens } from 'utils/requests.utils'
import { LocalStorageService } from 'utils/local-storage.utils'
import { FINGER_PRINT_KEY } from 'utils/fingerprint.utils'
import { addToDataLayer } from 'utils/GTMDataLayer.utils'

import { useSharedStores } from 'shared/hooks/use-shared-stores'
import { getUpgradeFlowDataLayerProps } from 'utils/OnboardingBannerDataLayer.utils'
import { UpgradePlanListModal } from '../../containers/cabinet/components/upgrade-plan/UpgradePlanListModal'

import { logOut } from '../../containers/app/api/session.api'

interface ProfileSettingsMenuProps {
  profileType: string
  user: {
    sellerUsername: string
    email: string
    avatar: {
      s200: string
    }
  }
  profileItem: {
    fullName: string
    userProfile: {
      firstName: string
      lastName: string
    }
    planTrial: boolean
    planName: string
    userProfileId: number
  }
  userStore: any
  profileStore: any
}

export const ProfileSettingsMenu: React.FC<ProfileSettingsMenuProps> = observer((props) => {
  const { profileType, user, profileItem, userStore, profileStore } = props
  const [language, setLanguage] = useState(getAllowedLanguages().en.value)
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const [showUpgradeModal, setShowUpgradeModal] = useState(false)
  const { userProfilesStore, countriesStore } = useSharedStores()

  const I18n = useI18n()
  const history = useHistory()

  useEffect(() => {
    if (isPopoverOpen) {
      addToDataLayer({
        ...SELLER_CLICK_EVENT,
        element: 'header',
        object: 'all',
        event_name: 'all_profileClick',
      })
    }
  }, [isPopoverOpen])

  useEffect(() => {
    const { locale } = userStore.item

    userProfilesStore.fetchFullList({ userId: userStore.item.id, expand: ['user_profile_verification'] })

    initLocale(locale)
  }, [])

  const togglePopover = () => setIsPopoverOpen(!isPopoverOpen)

  const isSeller = profileType === ACTIVE_PROFILES.seller
  const isPayer = profileType === ACTIVE_PROFILES.payer
  const isSalesTeamMember = profileType === ACTIVE_PROFILES.salesTeamMember

  const { fullName, userProfile } = profileItem || {}
  const { firstName = '', lastName = '' } = userProfile || {}
  const { sellerUsername } = user || {}
  const userFullName = fullName || `${firstName} ${lastName}`
  const profileName = isSeller ? getProfileFullName(userProfile) : userFullName
  const username = profileName || sellerUsername || user.email

  const initLocale = (locale) => {
    const currentLocale = I18n.currentLocale()

    if (locale !== currentLocale) {
      const isAvailableLanguage = Object.keys(getAllowedLanguages()).includes(currentLocale)
      setLanguage(isAvailableLanguage ? currentLocale : DEFAULT_FALLBACK_LOCALE)
    } else {
      setLanguage(locale)
    }
  }

  const updateLanguage = async (value) => {
    const resp = await userStore.updateItem({ locale: value })

    if (resp?.success) {
      setLanguage(resp.data.locale)
    }
  }

  const profileLink = () => {
    switch (profileType) {
      case ACTIVE_PROFILES.payer:
        return PAYER_PROFILE_PATH
      case ACTIVE_PROFILES.publisher:
        return PUBLISHER_PROFILE_PATH
      case ACTIVE_PROFILES.eloPublisher:
        return ELO_PUBLISHER_PROFILE_PATH
      case ACTIVE_PROFILES.teamMember:
        return TEAM_MEMBER_PROFILE_PATH
      case ACTIVE_PROFILES.salesTeamMember:
        return SALES_TEAM_MEMBER_PROFILE_PATH
      default:
        return '/cabinet/profile/edit'
    }
  }

  const myPlanLink = isSeller && '/cabinet/billing'
  const bankAccountsLink = () => {
    switch (profileType) {
      case ACTIVE_PROFILES.payer:
        return PAYER_BANK_ACCOUNTS_PATH
      case ACTIVE_PROFILES.publisher:
        return PUBLISHER_BANK_ACCOUNTS_PATH
      case ACTIVE_PROFILES.eloPublisher:
        return ELO_PUBLISHER_BANK_ACCOUNTS_PATH
      case ACTIVE_PROFILES.teamMember:
        return TEAM_MEMBER_BANK_ACCOUNTS_PATH
      default:
        return '/cabinet/bank_accounts'
    }
  }

  const sellerProfileSettings = [
    {
      label: I18n.t('react.cabinet.billing.title'),
      link: myPlanLink,
      id: 'my_plan',
    },
    { label: I18n.t('react.shared.profile'), link: profileLink, id: 'profile' },
    {
      label: I18n.t('react.shared.bank_accounts'),
      link: bankAccountsLink,
      id: 'bank_accounts',
    },
  ]

  const payerProfileSettings = [
    { label: I18n.t('react.shared.profile'), link: profileLink, id: 'profile' },
    {
      label: I18n.t('react.shared.bank_accounts'),
      link: bankAccountsLink,
      id: 'bank_accounts',
    },
  ]

  const salesTeamMemberProfileSettings = [{ label: I18n.t('react.shared.profile'), link: profileLink, id: 'profile' }]

  const profileSettingsOptions = (() => {
    switch (profileType) {
      case ACTIVE_PROFILES.seller:
        return sellerProfileSettings
      case ACTIVE_PROFILES.salesTeamMember:
        return salesTeamMemberProfileSettings
      default:
        return payerProfileSettings
    }
  })()

  const userLogOut = () => {
    logOut().then(() => {
      window.sessionStorage.removeItem(STARTED_WITH_ELOPAGE_KEY)
      removeTokens(false)
      LocalStorageService.removeItem(FINGER_PRINT_KEY, true)
    })
  }

  const avatarClasses = classNames('top-bar__avatar', {
    'top-bar__avatar--popover': isPopoverOpen,
  })

  const handleUpgrade = () => {
    if (profileStore?.item?.onTrialPeriod && profileStore?.item?.planTrial) {
      setShowUpgradeModal(true)
      addToDataLayer(
        getUpgradeFlowDataLayerProps({
          element: 'avatar_dropdown',
          eventName: 'upgrade_button_click',
          planId: profileStore?.item?.planId,
          isClick: true,
          sellerId: profileStore?.item.id,
          optionKey: 'upgrade_plan_flow',
        })
      )
    } else {
      window.open(UPGRADE_PLAN_LINK, '_blank')
    }
  }

  const firstUserProfile =
    userProfilesStore.list.find((item) => String(item.id) === String(profileItem?.userProfileId)) || {}
  const userProfileWithVatInfo = {
    ...firstUserProfile,
    vatPayer: firstUserProfile.vatPayer ? YES : NO,
  }
  const isEEACountry = !!countriesStore.euCountries.find((item) => item.id === firstUserProfile.countryCode)
  const requiredFields = getNewProfileRequiredFields(userProfileWithVatInfo, isEEACountry)
  const isUserProfileCompleted = isProfileCompleted(userProfileWithVatInfo, requiredFields)
  const profileRedirectLink = isSeller
    ? '/cabinet/profile/edit?seller_profile_edit=true'
    : `/${PROFILES_ROOT_PATH[profileType]}/profile/edit`

  return (
    <EloPopover
      show={isPopoverOpen}
      placement='top-right'
      onMouseEnter={() => {}}
      onMouseLeave={() => {}}
      onClickOutside={() => setIsPopoverOpen(false)}
      content={
        <div className='profile-settings-menu'>
          <div>
            <EloUserDisplay
              avatarUrl={user.avatar?.s200 || ''}
              email={user.email}
              username={username}
              tooltipPosition='bottom'
            />
            <div className='divider' />
            <div className='profile-settings'>
              {profileSettingsOptions.map(({ label, link, id }) => (
                <div key={id} className='profile-settings-item profile-settings-plan-item'>
                  <NavLink to={link}>{label}</NavLink>
                  {isSeller && id === 'my_plan' && (
                    <span>
                      <span className='profile-settings-item__plan'>
                        {profileItem.planTrial ? I18n.t('react.shared.free_trial') : profileItem.planName || ''}
                      </span>
                      {profileItem.planTrial && (
                        <EloButton size='small' variant='highlight' onClick={handleUpgrade}>
                          {I18n.t('react.shared.button.upgrade')}
                        </EloButton>
                      )}
                    </span>
                  )}
                  {!isSalesTeamMember && !isPayer && id === 'profile' && !isUserProfileCompleted && (
                    <EloStatusBadge
                      icon={<EloWarningIcon />}
                      status='warning'
                      tooltipContent={I18n.t('react.cabinet.profile.action_required.content')}
                      className='profile-settings-item__profile-icon'
                    >
                      <span onClick={() => history.push(profileRedirectLink)}>
                        {I18n.t('react.cabinet.common.states.action_required')}
                      </span>
                    </EloStatusBadge>
                  )}
                </div>
              ))}
            </div>
            <div className='divider' />
            <div className='profile-settings'>
              {(isSeller ? SELLER_SUPPORT_LINKS : SUPPORT_LINKS).map(({ label, link, id }) => (
                <a key={id} href={link} className='no-cross-domain' target='_blank'>
                  {label}
                </a>
              ))}
            </div>
            <div className='divider' />
            <div className='profile-language'>
              {Object.values(getAllowedLanguages()).map((item) => (
                <EloRadioButton
                  key={item.value}
                  checked={item.value === language}
                  onChange={() => updateLanguage(item.value)}
                >
                  {item.label}
                </EloRadioButton>
              ))}
            </div>
            <div className='divider' />
            <div className='profile-settings'>
              <button onClick={userLogOut} className='profile-settings-logout'>
                {I18n.t('react.shared.sign_out')}
              </button>
            </div>
          </div>
        </div>
      }
    >
      <div className={avatarClasses} id='profile-settings-menu' onClick={togglePopover}>
        <EloAvatar shape='circle' size='large' src={user.avatar?.s200} username={username || ''} />
      </div>
      {showUpgradeModal && (
        <UpgradePlanListModal
          isOpen={showUpgradeModal}
          onModalClose={() => {
            setShowUpgradeModal(false)
          }}
          trackingElement='avatar_dropdown'
        />
      )}
    </EloPopover>
  )
})
