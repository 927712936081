import { DataLayerEvent } from 'types/GTMDataLayer'

interface Props {
  onBannerButton?: DataLayerEvent
  onAppButton?: DataLayerEvent
  onViewPlans?: DataLayerEvent
  onViewDetails?: DataLayerEvent
}

export const CUSTOMIZATION_BANNER_SELLER: Pick<DataLayerEvent, 'event' | 'domain' | 'event_type'> = {
  event: 'ga-event',
  domain: 'seller',
  event_type: 'click',
}

export const getBannerDataLayerProps = (object: string): Props => ({
  onBannerButton: {
    ...CUSTOMIZATION_BANNER_SELLER,
    element: 'button',
    object: object,
    event_name: `${object}_learn`,
  },
  onAppButton: {
    ...CUSTOMIZATION_BANNER_SELLER,
    element: 'popup',
    object: object,
    event_name: `${object}_showPlans`,
  },
  onViewPlans: {
    ...CUSTOMIZATION_BANNER_SELLER,
    element: 'popup',
    object: object,
    event_name: `${object}_viewPlans`,
  },
  onViewDetails: {
    ...CUSTOMIZATION_BANNER_SELLER,
    element: 'popup',
    object: object,
    event_name: `${object}_planDetails`,
  },
})

export type UpgradeFlowElement =
  | 'option_banner'
  | 'option_slides'
  | 'plan_selection'
  | 'plan_confirmation'
  | 'marketing_site_plan_confirmation'
  | 'marketing_site_plans_list'
  | 'upgrade_button'
  | 'feature_plan_selection'
  | 'feature_plan_confirmation'
  | 'trial_header_notification'
  | 'avatar_dropdown'

interface UpgradeFlowDataLayerProps {
  optionKey: string
  element: UpgradeFlowElement
  planId: number
  nextPlanId?: number
  isClick: boolean
  reason?: string
  eventName?: string
  sellerId?: number
}

type UpgradeFlowDataLayerEvent = DataLayerEvent & {
  current_plan_id: number
  upgrade_plan_id?: number
  fail_reason?: string
  seller_id?: number
}

export const getUpgradeFlowDataLayerProps = ({
  optionKey,
  element,
  planId,
  nextPlanId,
  isClick,
  reason,
  eventName,
  sellerId,
}: UpgradeFlowDataLayerProps): UpgradeFlowDataLayerEvent => {
  const event: UpgradeFlowDataLayerEvent = {
    event: 'ga-event',
    domain: 'seller',
    event_type: isClick ? 'click' : 'view',
    object: optionKey,
    element: element === 'marketing_site_plans_list' ? 'plan_selection' : element,
    event_name: eventName || `${element}_${isClick ? 'click' : 'view'}`,
    current_plan_id: planId,
  }

  if (nextPlanId) {
    event.upgrade_plan_id = nextPlanId
  }

  if (reason) {
    event.fail_reason = reason
  }

  if (sellerId) {
    event.seller_id = sellerId
  }

  return event
}
