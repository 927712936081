import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import moment from 'moment'

import { ButtonVariantType } from '@elo-ui/components/elo-button/elo-button'
import { EloCaretLeftIcon, EloCopyIcon } from '@elo-ui/components/icons/regular'

import { EloButton } from '@elo-ui/components/elo-button'

import { ACTIVE_PROFILES, getProfileDetails, PROFILE_TYPES } from 'constants/profile.constants'

import { profile } from 'utils/profileHelper.utils'
import { getProfileFullName } from 'utils/user.utils'

import { ProfileSwitchingModal } from 'shared/components/side-menu/profile-switching/ProfileSwitchingModal'
import { useSharedStores } from 'shared/hooks/use-shared-stores'

import { ProfileSettingsMenu } from 'ui/top-bar/ProfileSettingsMenu'
import { addToDataLayer } from 'utils/GTMDataLayer.utils'
import { getUpgradeFlowDataLayerProps } from 'utils/OnboardingBannerDataLayer.utils'
import { UPGRADE_PLAN_LINK } from 'constants/helpCenter.constants'
import { UpgradePlanListModal } from '../../containers/cabinet/components/upgrade-plan/UpgradePlanListModal'
import payerCabinetNotificationsStore from '../../containers/shared/stores/payerNotifications.store'
import { useCabinetStore } from '../../containers/cabinet/hooks/use-cabinet-stores'

import { NotificationsMenu } from './NotificationsMenu'

import './top-bar.scss'

interface TopBarProps {
  title?: string | React.ReactNode
  backButtonLink?: string
  backButtonText?: string
  backButtonIcon?: React.ReactNode
  backButtonVariant?: ButtonVariantType
  copyLink?: string
  copyActionText?: string
  customButtonAction?: () => void
  customButtonText?: string
  secondaryButtonText?: string
  secondaryButtonAction?: () => void
  flat?: boolean
  className?: string
  customRightInfo?: React.ReactNode
}

export const TopBar: React.FC<TopBarProps> = observer(
  ({
    title,
    backButtonLink,
    backButtonText,
    backButtonIcon = <EloCaretLeftIcon />,
    backButtonVariant = 'secondary',
    copyLink,
    copyActionText,
    customButtonAction,
    customButtonText = null,
    secondaryButtonText,
    secondaryButtonAction,
    flat,
    className,
    customRightInfo,
  }) => {
    const profileType = profile.profileType
    const stores = useCabinetStore()
    const {
      userStore,
      sellerStore,
      payerStore,
      teamMemberStore,
      salesTeamMemberStore,
      publisherStore,
      eloPublisherStore,
    } = useSharedStores()
    const { cabinetNotificationsStore } = stores

    let profileStore
    switch (profileType) {
      case ACTIVE_PROFILES.seller:
        profileStore = sellerStore
        break
      case ACTIVE_PROFILES.payer:
        profileStore = payerStore
        break
      case ACTIVE_PROFILES.teamMember:
        profileStore = teamMemberStore
        break
      case ACTIVE_PROFILES.salesTeamMember:
        profileStore = salesTeamMemberStore
        break
      case ACTIVE_PROFILES.publisher:
        profileStore = publisherStore
        break
      case ACTIVE_PROFILES.eloPublisher:
        profileStore = eloPublisherStore
        break
      default:
        profileStore = sellerStore
        break
    }

    const [isManager, isPayer, isSeller] = [ACTIVE_PROFILES.manager, ACTIVE_PROFILES.payer, ACTIVE_PROFILES.seller].map(
      (type) => type === profileType
    )

    const { item: user } = userStore
    const { item: profileItem } = profileStore

    const profileDetails = getProfileDetails()[PROFILE_TYPES[profileType]]
    const { fullName, userProfile, trialPeriodEndDate, planTrial } = profileItem || {}
    const { sellerUsername } = user || {}
    const profileName = isSeller ? getProfileFullName(userProfile) : fullName
    const username = profileName || sellerUsername || user.email

    let notificationsStore = null as unknown as any
    const getNotificationsStore = () => {
      notificationsStore =
        profile.profileType === ACTIVE_PROFILES.payer ? payerCabinetNotificationsStore : cabinetNotificationsStore

      return notificationsStore
    }

    const [isSwitchingModalOpen, setIsSwitchingModalOpen] = useState(false)
    const [showUpgradeModal, setShowUpgradeModal] = useState(false)
    notificationsStore = (isSeller || isPayer) && getNotificationsStore()

    const toggleSwitchingModal = () => setIsSwitchingModalOpen(!isSwitchingModalOpen)

    const topbarClasses = classNames(
      'topbar',
      {
        'topbar--flat': flat,
      },
      className
    )

    const notificationsClasses = classNames('top-bar__notifications', {
      'top-bar__notifications--empty': notificationsStore.notificationCounts?.get('totalCount') == 0,
    })

    useEffect(() => {
      if (notificationsStore) {
        notificationsStore.fetchUnreadCount()
      }
    }, [notificationsStore])

    const currentDate = moment().startOf('day')
    const trialFormattedDay = moment(trialPeriodEndDate)
    const dayCountOfTrial = Math.floor(moment.duration(trialFormattedDay.diff(currentDate)).asDays())

    return (
      <div className={topbarClasses}>
        <h2 className='float-left title'>{title || ''}</h2>
        <div className='topbar__user_info topbar__user_info--buttons'>
          {customRightInfo}
          {planTrial && (
            <div className='topbar__trial-period'>
              <div className='topbar__trial-period--title'>
                {I18n.t('react.cabinet.top_bar.trial_period', { count: dayCountOfTrial })}
              </div>
              <a
                id='trial-period-upgrade-link'
                className='topbar__trial-period--link'
                href='#'
                onClick={(e) => {
                  e.preventDefault()
                  if (sellerStore?.item?.onTrialPeriod && sellerStore?.item?.planTrial) {
                    setShowUpgradeModal(true)
                    addToDataLayer(
                      getUpgradeFlowDataLayerProps({
                        element: 'trial_header_notification',
                        eventName: 'upgrade_button_click',
                        planId: sellerStore?.item?.planId,
                        isClick: true,
                        sellerId: sellerStore?.item.id,
                        optionKey: 'upgrade_plan_flow',
                      })
                    )
                  } else {
                    window.open(UPGRADE_PLAN_LINK, '_blank')
                  }
                }}
              >
                {I18n.t('react.shared.button.upgrade_now')}
              </a>
            </div>
          )}
          {backButtonLink && (
            <EloButton
              variant={backButtonVariant}
              onClick={() => (window.location.href = backButtonLink)}
              className='button-back-to-list'
              leftIcon={backButtonIcon}
            >
              {backButtonText || I18n.t('react.shared.button.back_to_list')}
            </EloButton>
          )}
          {copyLink && (
            <EloButton
              variant='secondary'
              className='button-copy-link'
              onClick={() => copyLink}
              leftIcon={<EloCopyIcon />}
            >
              {copyActionText || I18n.t('react.cabinet.copy_deep_link')}
            </EloButton>
          )}
          {customButtonText && (
            <EloButton variant='secondary' onClick={customButtonAction}>
              {customButtonText}
            </EloButton>
          )}
          {secondaryButtonText && (
            <EloButton variant='secondary' onClick={secondaryButtonAction}>
              {secondaryButtonText}
            </EloButton>
          )}
          {!isManager && (
            <EloButton variant='secondary' className='button-profile-type' onClick={toggleSwitchingModal}>
              {I18n.t('react.shared.profile_type_account', { profileType: profileDetails?.title })}
            </EloButton>
          )}
          <div className='topbar__user_info'>
            {isSeller && (
              <div className={notificationsClasses}>
                <NotificationsMenu
                  {...{
                    counts: notificationsStore.notificationCounts,
                    data: { profileType, notificationsStore: notificationsStore },
                  }}
                />
              </div>
            )}
            {!isManager && (
              <ProfileSettingsMenu
                profileType={profileType}
                user={user}
                profileItem={profileItem}
                userStore={userStore}
                profileStore={profileStore}
              />
            )}
          </div>
        </div>
        {isSwitchingModalOpen && (
          <ProfileSwitchingModal
            currentProfile={profileType}
            toggle={toggleSwitchingModal}
            isOpen={isSwitchingModalOpen}
            username={username}
            {...{
              userStore,
              sellerStore,
              publisherStore,
              eloPublisherStore,
              teamMemberStore,
              salesTeamMemberStore,
              payerStore,
            }}
          />
        )}
        {showUpgradeModal && (
          <UpgradePlanListModal
            isOpen={showUpgradeModal}
            onModalClose={() => {
              setShowUpgradeModal(false)
            }}
            trackingElement='trial_header_notification'
          />
        )}
      </div>
    )
  }
)
